import React from "react";
import { Row, Col } from "react-bootstrap";
import "./Banner.css";
import { GoLocation } from "react-icons/go";
import { HiOutlineMail } from "react-icons/hi";
import { AiOutlineClockCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
export default function Banner({ isDivider, bottomBanner }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact");
  };
  return (
    <div
      className='d-flex justify-content-start justify-content-md-end align-items-center p-3 bannerHeight'
      style={{
        backgroundColor: isDivider ? "#e60405" : "#29363B",
        color: "white",

        height: bottomBanner ? "7rem" : isDivider ? "5rem" : "100%",
        marginTop: bottomBanner ? "2rem" : "0rem",
      }}
    >
      {isDivider && bottomBanner && (
        <div className='d-flex w-100 justify-content-around align-items-center bannerContainer'>
          <span className='fs-5'>
            SixdPro: Provides Consulting Services & Support in Project and
            Opertion Management.
          </span>
          <button className='button bannerButton m-0' onClick={handleClick}>
            CONTACT US
          </button>
        </div>
      )}
      {!isDivider && (
        <Row style={{ width: "90%" }}>
          <Col md={4}>
            <div className='mx-3 d-flex align-items-center '>
              <GoLocation />
              <span className='ms-2'>
              2631 Dewdney Ave Regina SasKatchewan S4T0X4
              </span>
            </div>
          </Col>
          <Col md={4}>
            {" "}
            <div className='mx-3 d-flex align-items-center'>
              <AiOutlineClockCircle />
              <span className='ms-2'>
                {" "}
                Mon - Sat 8.00 - 18.00. Sunday CLOSED
              </span>
            </div>
          </Col>
          <Col md={4}>
            {" "}
            <div className='mx-3 d-flex align-items-center'>
              <HiOutlineMail />
              <span className='ms-2'>farhan.m@sixdpro.com</span>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}
