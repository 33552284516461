import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Services from "../../Components/Services/Services";
const AssetsManagementContent = {
  desc: "Our team specializes in creating customized tools for asset and facility management using the powerful capabilities of Power BI and Power Apps. These tools allow clients to easily track, manage, and optimize their assets and facilities in real-time, helping to improve efficiency, reduce costs, and streamline operations. Using Power BI, we can help clients create interactive dashboards and visualizations that provide insights into the performance and utilization of their assets and facilities. This allows clients to quickly identify trends, opportunities for improvement, and potential issues, enabling them to make informed decisions about their operations. Power Apps enable clients to build custom applications that can be accessed from any device, allowing for easy access to asset and facility management data and tools. This allows clients to manage their assets and facilities more effectively, whether they are on site or working remotely. Our team has extensive experience in creating and implementing asset and facility management tools using Power BI and Power Apps, and we are committed to helping clients achieve their operational goals. Contact us to learn more about how we can support your asset and facility management needs.",
  services: [
    "Cleansing and Organization of raw Data",
    "Extracting Meaning Full Information and Creating Visuals",
    "Establishing error free link to get a real time information about the operations",
    "Provide 24/7 maintenance and support services for the tools we built",
  ],
  isVideo: true,
  videoSrc: "/Video/6d.mp4",
};
export default function AssetsFacilityManagement() {
  return (
    <Container fluid className='m-0 p-0 services-container'>
      <Row className='m-0 p-0 mb-5'>
        <Col sm={8} md={8} lg={12} className='m-0 services-title-container'>
          <span className='align-self-center title-services'>
            <span style={{ color: "#e60405" }}> Assets</span> Facility
            Management
          </span>
        </Col>
        <Services content={AssetsManagementContent} />
      </Row>
    </Container>
  );
}
