import React from "react";
import "./TeamCard.css";
import { Col } from "react-bootstrap";
export default function TeamCard({ member }) {
  console.log(member);
  return (
    <Col
      lg={3}
      md={4}
      sm={6}
      className=' mt-5 d-flex flex-column align-items-center justify-content-space-between '
      style={{ minHeight: "18rem" }}
    >
      <img
        src={member?.image}
        alt='Rectangle-1'
        border='0'
        className='member-img'
      />
      <span className='member-name'>{member?.name}</span>
      <span className='member-position'>{member?.position}</span>
    </Col>
  );
}
