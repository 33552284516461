import React from "react";
import Banner from "../Components/Banner/Banner";
import Header from "../Components/Header/Header";
import MiddleSection from "../Components/MiddleSection/MiddleSection";
import Testimonies from "../Components/Testimonies/Testimonies";
import Partner from "../Components/PartnerSection/Partner";

export default function Home() {
  return (
    <>
      <Banner isDivider={true} />
      <Header />
      <MiddleSection />
      <Partner />
      <Testimonies />
      <Banner isDivider={true} bottomBanner={true} />
    </>
  );
}
