const people = [
  {
    id: 1,
    image: "/Images/Bala-Athiappan.jpg",
    company: "Worley Parsons",
    name: "Bala Athiappan",
    quote:
      "Farhan had worked for me for 3 years on CCRL Project in Regina. He is very professional and I recommend his planning and scheduling services to any client for green and brown field construction work.",
  },
  {
    id: 2,
    image: "/Images/00.jpg",
    company: "Vinci Canada",
    name: "Antoine DUCHENE",
    quote:
      "Farhan’s extensive knowledge of P6 allowed to set up and follow with accuracy the general schedule on the project. He provided me with the monthly KPI targets to be monitored and helped following the progress of various trades. I recommend his services.",
  },
  {
    id: 3,
    image: "/Images/Dave-C-Final.jpg",
    company: "Persons",
    name: "David Callander",
    quote: "Highly recommended. very professional. delivered on time",
  },
  {
    id: 4,
    image: "/Images/i2.jpg",
    company: "Carmacks",
    name: "Julien JOLLIVET",
    quote:
      "I used their services for assigning the quantities and man-hour in the Primavera for my project schedule and received the customized reports on weekly bases. I am a regular client and always use their services as needed. Highly recommended. ",
  },
  {
    id: 5,
    image: "/Images/5-122x122.jpg",
    name: "Ahmed Ismail",
    company: "Devon Energy",
    quote:
      "We received Farhan's services on Jackfish 3 Project in Conklin, Alberta. He has an In-depth Knowledge of Primavera and Project Controls Process.  His deliverable was always on time.",
  },
];

export default people;
