import React, { useRef, useEffect } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { useNavigate } from "react-router-dom";
let touchStartTime;

const Cube = ({ isClickable, setIsClickable }) => {
  const gltf = useGLTF("/Models/CubeFinal.glb");
  const ref = useRef();
  const navigate = useNavigate();
  const { camera } = useThree();

  var raycaster = new THREE.Raycaster();

  // Create an AnimationMixer, and get the list of AnimationClip instances
  const mixer = new THREE.AnimationMixer(gltf.scene);
  const clips = gltf.animations;

  if (!isClickable) {
    mixer.clipAction(clips[0]).loop = THREE.LoopOnce;
    mixer.clipAction(clips[0]).clampWhenFinished = true;
    mixer.clipAction(clips[0]).play();
  }

  mixer.addEventListener("finished", () => {
    setIsClickable(true);
  });

  const onDocumentMouseDown = (event) => {
    if (ref.current) {
      var mouse = new THREE.Vector2();

      //console.log("Desktop");
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y =
        -(event.clientY / window.innerHeight) *
          (window.innerWidth < 768 ? 1 : 2) +
        1;

      //console.log(mouse);
      raycaster.setFromCamera(mouse, camera);
      var intersects = raycaster.intersectObjects(ref.current.children, true);
      console.log(intersects);
      if (intersects.length > 0) {
        let name = intersects[0].object.name;

        switch (name) {
          case "Cube006_1":
            //home

            //console.log("home");
            navigate("/");
            break;
          case "Cube005_1":
            //2d
            // console.log("2d");
            navigate("/services/engineering/civil");
            break;
          case "Cube004_1":
            //3d
            // console.log("3d");
            navigate("/services/model/architectural-bim");
            break;
          case "Cube003_1":
            //4d
            // console.log("4d");
            navigate("/services/time-management");
            break;
          case "Cube007_1":
            //5d
            // console.log("5d");
            navigate("/services/costing");
            break;
          case "Cube002_1":
            //6d
            // console.log("6d");
            navigate("/services/assets-facility-management");
            break;
          default:
            break;
        }
      }
    }
  };
  //double click

  document.addEventListener("dblclick", onDocumentMouseDown);
 
  useFrame((delta) => {
    mixer.update(0.02);
  });

  return (
    <group
      onPointerOver={() => {
        document.body.style.cursor = "pointer";
      }}
      onPointerLeave={() => (document.body.style.cursor = "default")}
      position={[0, 0.35, 0]}
      scale={window.innerWidth < 756 ? [0.85, 0.85, 0.85] : [1, 1, 1]}
    >
      <primitive object={gltf.scene} ref={ref} />
    </group>
  );
};

export default Cube;
