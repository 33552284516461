import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Services from "../../Components/Services/Services";
const CostingContent = {
  desc: "Our team specializes in providing comprehensive budgeting, financial planning, change management and cost control services for projects of all sizes and industries. We offer a range of services to help clients accurately estimate budgets, track costs, and forecast future expenses. Our team has extensive experience in creating detailed budgets and cash flow projections, allowing us to provide clients with a clear understanding of their financial situation and the resources needed to successfully complete their project. We use and can build advanced tools and techniques to analyze data and provide accurate, reliable estimates. Our cost tracking services help clients stay on track and within budget, by providing regular updates and alerts when expenses exceed predetermined limits. We also offer forecast budgeting services to help clients plan for future expenses and make informed decisions about 4 M's ( Manpower, Machinery, Material and Money). Whether you are starting a new project from a concept or looking to optimize your existing budget, our team has the expertise and resources to help you succeed. Contact us to learn more about how we can support your financial planning needs.",
  services: [
    "Estimation",
    "Budgeting",
    "Cost Control",
    "Forecasting",
    "Change Management",
  ],
  isVideo: true,
  
  videoSrc: "/Video/5d.mp4",
};
export default function Costing() {
  return (
    <Container fluid className='m-0 p-0 services-container'>
      <Row className='m-0 p-0 mb-5'>
        <Col sm={8} md={8} lg={12} className='m-0 services-title-container'>
          <span className='align-self-center title-services'>
            <span style={{ color: "#e60405" }}>Costing</span> and Estimation
          </span>
        </Col>
        <Services content={CostingContent} />
      </Row>
    </Container>
  );
}
