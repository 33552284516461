import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./AboutUs.css";

function AboutUs() {
  return (
    <Container className='mt-4 about-container'>
      <span className='title-about'>
        About <span style={{ color: "#e60405" }}> SixDPro </span>
      </span>
      <Row style={{ justifyContent: "space-around" }} className='mb-4'>
        <Col sm={12} md={12} lg={8} className='mt-4'>
          <span style={{ fontSize: "18px" }}>
            Welcome to our team! We are a group of highly qualified
            professionals with expertise in engineering, planning, scheduling,
            quality control, and IT in project and operation management. Our
            team specializes in providing a range of services, including 3D
            modeling, 4D scheduling, 5D cost management, and 6D tool creation
            for asset and facility management using PowerBI and PowerApps.
            <br />
            <br />
            Our team of engineers and IT professionals are dedicated to
            delivering high-quality, cost- effective solutions for our clients.
            We are committed to using the latest technologies, including PowerBI
            and PowerApps, to help our clients manage their projects and
            operations safely, effectively and efficiently.
          </span>
          <div className='highlight-text-about'>
            <span className='highlight-text-content-about'>
              Whether you are looking to manage your construction and turn
              around projects or improve your asset and facility management
              processes, streamline your operations, or simply need expert
              guidance, our team is here to help. Contact us today to learn more
              about how we can support your business needs.
            </span>
          </div>
        </Col>
        <Col sm={12} md={12} lg={3} className='mt-4 help-about-container'>
          <div className='help-content'>
            <span className='help-title'>How can we help you?</span>
            <span className='help-text'>
              Contact us at the SixDpro office or submit a business inquiry
              online.
            </span>
            <Link to='/contact'>
              <button className='help-button'>CONTACT US</button>
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AboutUs;
