import React from "react";
import "../../Components/Training/Training.css";

export default function Synchro() {
  return (
    <div className="training-container">
      <h1>Training</h1>
      <p>Please contact for Self paced Training Video</p>
    </div>
  );
}
