import React from "react";
import Card from "./card";
import Reason from "./Reason";

export default function Training() {
  return (
    <div className=" h-full w-full flex flex-col justify-center items-center px-8 py-12">
      <h1 className="md:w-2/4 sm:w-full text-black text-4xl pb-4 font-semibold text-center">
        <span className="text-[#e60405]">Primavera P6 Training</span> for
        Project Planning & Scheduling
      </h1>
      <div className=" grid md:grid-cols-4 sm:grid-cols-2 md:px-20 sm:px-4 gap-4 mb-4">
        <Card
          info={
            "GET NEW SKILLS OR IMPROVE EXISTING ONES WITH IN-DEPTH KNOWLEDGE OF PRIMAVERA!"
          }
        />
        <Card info={" GET INTO PROJECT & CONSTRUCTION MANAGEMENT!"} />
        <Card
          info={
            " LEARN WORLD-RENOWNED SOFTWARE FOR PROJECT PLANNING AND SCHEDULING"
          }
        />

        <Card
          info={
            " COMPREHENSIVE PRIMAVERA TRAINING FROM BASIC TO ADVANCED WITH FIELD WORK EXAMPLES"
          }
        />
      </div>

      <div className="w-full flex flex-col justify-center items-center gap-5 py-10  mb-8">
        <span className="md:w-2/4 sm:w-full text-center">
          Are you an engineering student or a recent graduate looking to enhance
          your project management skills? Or perhaps you are a construction
          worker, supervisor, or field worker aiming to advance your career? Our
          Primavera training is designed just for you!
        </span>
        <div className="w-full flex flex-col justify-center items-center ">
          <h2 className="text-3xl py-4 font-semibold">
            Who Can <span className="text-[#e60405]">Benefit</span>
          </h2>
          <ul className="text-black list-disc flex flex-wrap justify-center items-center gap-4 md:px-20 sm:px-4">
            <Reason icon="graduate" text="Engineering Students" />
            <Reason
              icon="certification"
              text="Fresh Graduates in Any Engineering Discipline"
            />
            <Reason icon="job" text="Construction Workers & Supervisors" />
            <Reason icon="tools" text="Field Workers & Industrial Workers" />
            <Reason
              icon="industry"
              text="Professionals from Oil & Gas, SAGD, Mining, Commercial, Residential, Industrial, and Infrastructure Projects"
            />
          </ul>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div className="flex flex-col justify-center items-center text-center bg-gray-100 p-6 rounded-lg">
          <h2 className="text-2xl font-semibold mb-4">Course Highlights:</h2>
          <h3 className="text-lg font-semibold mb-2">Training Videos:</h3>
          <p>
            A well-organized course consists of 8 chapters and a total of 114
            videos offering 20 hours of training. Each video averages around 10
            minutes providing comprehensive and detailed explanations in a very
            simple way.
          </p>

          <h3 className="text-lg font-semibold mt-4">Expert Instructor:</h3>
          <p>
            Gain insights from an instructor with over 25 years of experience in
            project controls, planning, and scheduling.
          </p>

          <h3 className="text-lg font-semibold mt-4">Real-World Examples:</h3>
          <p>Apply what you learn through practical real-world examples.</p>
        </div>

        <div className="flex flex-col justify-center items-center text-center bg-gray-100 p-6 rounded-lg">
          <h2 className="text-2xl font-semibold mb-4">
            Interactive Sessions and One-on-One Training:
          </h2>
          <p>
            Join us on Fridays and Saturdays for hands-on learning, Q&A, and
            personalized guidance to ensure you master Primavera.
          </p>

          <h3 className="text-lg font-semibold mt-4">
            Special Student Discount:
          </h3>
          <p>
            Course fee is $500, but students can enroll for just $400 with a 20%
            discount!
          </p>

          <h3 className="text-lg font-semibold mt-4">Handouts Included:</h3>
          <p>Reference materials provided as part of the course cost.</p>
        </div>
      </div>

      <div className="w-full flex flex-col justify-center text-center items-center gap-2 ">
        <h2 className="text-3xl py-4 font-semibold">
          Training Schedule & Contact Information
        </h2>
        <h3 className="text-lg font-semibold">Training Schedule:</h3>
        <p>Dates: Call for details</p>
        <p>Days: Friday & Saturday Only</p>
        <p>Venue: Regina - Exact location will be provided soon.</p>
        <p>Seats Available: Only 10 seats available – secure your spot now!</p>

        <h3 className="text-lg font-semibold mt-4">Contact Information:</h3>
        <p>
          Call to enroll or send email to{" "}
          <a href="mailto:Farhan.m@sixdpro.com" className="text-[#e60405]">
            Farhan.m@sixdpro.com
          </a>
        </p>

        <h3 className="text-lg font-semibold mt-4">Video Purchase Option:</h3>
        <p>
          Course videos available for purchase at an additional cost at the end
          of the course.
        </p>
      </div>
    </div>
  );
}
