import { Row, Col } from "react-bootstrap";
import "./Services.css";

export default function Services({ content }) {
  return (
    <>
      {" "}
      <Col
        sm={12}
        md={12}
        lg={12}
        className='mt-4 mb-4 services-content-container'
      >
        <div className='services-content'>
          <span className='content'>{content.desc}</span>
        </div>
      </Col>
      {content.isEmpty ? (
        ""
      ) : (
        <Row className='services-details-section h-100'>
          <Col
            sm={12}
            md={12}
            lg={content.isVideo ? 5 : 3}
            className='services-names-container'
          >
            <span className='fs-1' style={{ color: "#e60405" }}>
              Services
            </span>
            <div className=' mt-4 mb-4 services-names-list'>
              {content.services?.map((service, index) => (
                <span className='service-name' key={index}>
                  {service}
                </span>
              ))}
            </div>
          </Col>
          {content.isVideo ? (
            <Col
              sm={12}
              md={12}
              lg={6}
              className='mt-4 d-flex align-items-center justify-content-center '
            >
              <video controls width='100%'>
                <source src={content.videoSrc} type='video/mp4' />
                Sorry, your browser doesn't support videos.
              </video>
            </Col>
          ) : (
            <>
              {" "}
              <Col
                sm={12}
                md={12}
                lg={4}
                className='services-left-image-container d-flex flex-col justify-content-between'
              >
                <div className='left-img'>
                  <img
                    className='service-img'
                    alt='service'
                    src={content?.images[0]}
                  />
                </div>
                <div className='left-img'>
                  <img
                    className='service-img'
                    alt='service'
                    src={content?.images[1]}
                  />
                </div>
              </Col>
              <Col
                sm={12}
                md={12}
                lg={4}
                className='services-right-image-container'
              >
                <div className='right-img'>
                  <img
                    className='service-img'
                    src={
                      content?.images[2] ? content.images[2] : content.images[0]
                    }
                    alt='Steel Structural BIM Modeling'
                  />
                </div>
              </Col>
            </>
          )}
        </Row>
      )}
    </>
  );
}
