import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Services from "../../../Components/Services/Services";

const MEPBIMContent = {
  desc: "We provide comprehensive MEP BIM Services that can be utilized throughout the project’s lifecycle from planning and designing to fabrication, assembly and installation to facility management. Our MEP BIM Models are clash free and are always in accordance to international codes and standards.",
  services: [
    "CAD to MEP BIM Conversion Services",
    "MEP Shop Drawing Services",
    "Quantity Takeoff Services",
  ],
  images: [
    "/Images/Services/MEP/1.jpg",
    "/Images/Services/MEP/2.jpg",
    "/Images/Services/MEP/3.jpg",
  ],
};

export default function MEPBIM() {
  return (
    <Container fluid className='m-0 p-0 services-container'>
      <Row className='m-0 p-0 mb-5'>
        <Col sm={12} md={12} lg={12} className='m-0 services-title-container'>
          <span className='title-services align-self-center '>
            <span style={{ color: "#e60405" }}> MEP</span> BIM Services
          </span>
        </Col>
        <Services content={MEPBIMContent} />
      </Row>
    </Container>
  );
}
