import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Services from "../../../Components/Services/Services";
const CivilEngineeringContent = {
  desc: "Our team specializes in providing comprehensive civil engineering services for industrial projects, including foundations, earthwork, structures, and buildings. We have extensive experience in the design and construction of these types of projects, and we are committed to delivering high-quality results that meet the needs of our clients. Our team is skilled in reviewing engineering drawings and performing quality checks to ensure that all work is completed to the highest standards. We also provide supervision of construction work related to civil engineering, ensuring that projects are completed safely and efficiently. Whether you are starting a new industrial project from scratch or looking to optimize an existing project, our team has the expertise and resources to help you succeed. We are registered with APEGES and have certificate of authorization to work in Saskatchewan, Canda. Contact us to learn more about how we can support your civil engineering needs.",
  services: [],
  images: [],
  isEmpty: true,
};
export default function Civil() {
  return (
    <Container fluid className='m-0 p-0 services-container'>
      <Row className='m-0 p-0 mb-5'>
        <Col sm={8} md={8} lg={12} className='m-0 services-title-container'>
          <span className='align-self-center title-services'>
            <span style={{ color: "#e60405" }}> Civil</span> Engineering
          </span>
        </Col>
        <Services content={CivilEngineeringContent} />
      </Row>
    </Container>
  );
}
