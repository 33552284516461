import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Services from "../../Components/Services/Services";
const TimeManagContent = {
  desc: "Our team specializes in providing advanced 4D scheduling services to help clients optimize the progress of their projects and ensure their successful completion within the project timeline. By linking a 3D engineering model to scheduling software like Primavera P6, we are able to simulate the progress of a project and identify potential issues in real-time. Our 4D scheduling services allow clients to identify clashes and conflicts, find resource density, and track the usage of equipment, material, and labor. This helps clients to optimize the allocation of resources and minimize delays, ensuring that the project is completed safely and efficiently. Our team has extensive experience in implementing 4D scheduling solutions and is committed to helping clients achieve their project goals. Contact us to learn more about how we can support your scheduling needs and ensure the successful completion of your project.",
  services: [
    "In 4D Scheduling, we link the 3D Model with the Construction schedule using Primavera, Power BI and Synchro Pro software to simulate the Construction with the Timeline. Please contact us for more details. ",
  ],
  isVideo: true,
  videoSrc: "/Video/4d.mp4",
};
export default function TimeManagement() {
  return (
    <Container fluid className='m-0 p-0 services-container'>
      <Row className='m-0 p-0 mb-5'>
        <Col sm={8} md={8} lg={12} className='m-0 services-title-container'>
          <span className='align-self-center title-services'>
            <span style={{ color: "#e60405" }}>Scheduling </span>Services
          </span>
        </Col>
        <Services content={TimeManagContent} />
      </Row>
    </Container>
  );
}
