import React from "react";
import {
  FaUserGraduate,
  FaGraduationCap,
  FaHardHat,
  FaTools,
  FaIndustry,
} from "react-icons/fa";

const Reason = ({ icon, text }) => {
  // Define the icon mapping array
  const iconMap = {
    graduate: <FaUserGraduate size={48} />,
    certification: <FaGraduationCap size={48} />,
    job: <FaHardHat size={48} />,
    tools: <FaTools size={48} />,
    industry: <FaIndustry size={48} />,
  };

  return (
    <div
      className={` border shadow-lg p-4 rounded-lg md:w-1/3 w-full flex justify-start items-center`}
    >
      <div>{iconMap[icon]}</div> {/* Render the icon */}
      <p className="ml-4">{text}</p> {/* Render the reason text */}
    </div>
  );
};

export default Reason;
