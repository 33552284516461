import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Services from "../../../Components/Services/Services";
const BIMParametricContent = {
  desc: "We create Structural BIM models that accurately represent the dimensions and placements of steel components in the building design. We have thorough quality checks in place that ensure that the final deliverables are in compliance with the required codes and of high quality. Additionally, the shop drawings extracted from our error free 3D model lead to a smooth fabrication, assembly and installation process.",
  services: [
    "Architectural Revit Family Creation",
    "HVAV, Electrical and Plumbing Family Creation",
    "Structural BIM Content Creation",
  ],
  images: [
    "/Images/Services/Parametric/1.jpg",
    "/Images/Services/Parametric/2.jpg",
    "/Images/Services/Parametric/3.jpg",
  ],
};
export default function BIMParametric() {
  return (
    <Container fluid className='m-0 p-0 services-container'>
      <Row className='m-0 p-0 mb-5'>
        <Col sm={12} md={12} lg={12} className='m-0 services-title-container'>
          <span className='title-services align-self-center '>
            BIM <span style={{ color: "#e60405" }}> Parametric </span>
            Families
          </span>
        </Col>
        <Services content={BIMParametricContent} />
      </Row>
    </Container>
  );
}
