import React from "react";
import { Html } from "@react-three/drei";
import { Spinner } from "react-bootstrap";

//Loader Component
const Loader = () => {
  return (
    <Html center>
      <div className='loader'>
        <Spinner animation='border' variant='light' />
      </div>
    </Html>
  );
};

export default Loader;
