import React from "react";
import { Container } from "react-bootstrap";
import TeamCards from "../Components/TeamCards/TeamCards";
import Banner from "../Components/Banner/Banner";

export default function Team() {
  return (
    <>
      <Container className='d-flex flex-column mt-5'>
        <span className='title align-self-center m-auto'>
          Meet Our <span style={{ color: "#e60405" }}>Team</span>{" "}
        </span>
        <TeamCards />
      </Container>
      <Banner isDivider={true} bottomBanner={true} />
    </>
  );
}
