import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls, Stage } from "@react-three/drei";
import React, { Suspense, useEffect } from "react";
import { gsap } from "gsap";
import Loader from "./Scene/Loader";
import Model from "./Scene/Model";
import "./Header.css";

export default function Header() {
  const [isClickable, setIsClickable] = React.useState(false);
  useEffect(() => {
    if (isClickable) {
      gsap.to(".header-text-container", {
        duration: 1,
        opacity: 1,
      });
    }
  }, [isClickable]);
  return (
    <div
      className='header-container'
      style={{
        height: "65vh",
        position: "relative",
      }}
    >

      <Canvas camera={{ fov: 75, position: [0, 0, 4] }}>
        {/* <ambientLight intensity={0.5} /> */}
        {/* <directionalLight position={[-1, 0, 10]} intensity={0.3} /> */}
        <Suspense fallback={<Loader />}>
          <Model isClickable={isClickable} setIsClickable={setIsClickable} />
          <Environment preset="sunset" />

          {isClickable && <OrbitControls />}
        </Suspense>
      </Canvas>
      <div div className='header-text-container' >
        <span className='header-text'>
          {window.innerWidth < 786 ? "Double Tap on" : "Double Click"} any dimension to
          learn more about our services
        </span>
      </div >
    </div >
  );
}
