import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./Contact.css";
import axios from "axios";
const contactForm = {
  name: "",
  phone: "",

  email: "",
  message: "",
  subject: "",
};
export default function Contact() {
  const [form, setForm] = React.useState(contactForm);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const isValid = () => {
    return (
      form.name === "" ||
      form.email === "" ||
      form.subject === "" ||
      form.message === ""
    );
  };
  const handleSubmit = (e) => {
    console.log("form", form);
    e.preventDefault();
    axios
      .post("https://formsubmit.co/ajax/farhan.m@sixdpro.com", {
        Email: form.email,
        Subject: form.subject,
        Name: form.name,
        Phone: form.phone,
        Message: form.message,
      })
      .then((res) => {
        console.log("Form Submitted");
      });
    setForm(contactForm);
  };
  return (
    <Container className='mt-4 contact-container'>
      <Row className=''>
        <Col sm={12} md={12} lg={4} className='mt-4 contact-details'>
          <span className='title'>
            Contact <span style={{ color: "#e60405" }}>Us</span>
          </span>
          <div className='description'>
            <span className='desc'>
              Please let us know if you have a question, want to leave a
              comment, or would like further information about Sixdpro and{" "}
              <a href='https://itknocks.com/'>ITKnocks</a>.
            </span>
            <span className='details'>Contact Details</span>
            <span className='address'>
              2631 Dewdney Ave Regina SasKatchewan S4T0X4
            </span>
            <span className='mail'>farhan.m@sixdpro.com</span>
          </div>
        </Col>
        <Col sm={12} md={12} lg={8} className='d-flex form-container'>
          <div className='div1'>
            <form className='contact-form'>
              <div className='form-group form-input'>
                <input
                  type='text'
                  className='form-control'
                  id='name'
                  placeholder='Email *'
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                  value={form.email}
                />
              </div>
              <div className='form-group form-input'>
                <input
                  type='text'
                  className='form-control'
                  id='name'
                  placeholder='Full Name *'
                  value={form.name}
                  onChange={(e) => setForm({ ...form, name: e.target.value })}
                />
              </div>
              <div className='form-group form-input'>
                <input
                  type='text'
                  className='form-control'
                  id='name'
                  placeholder='Phone No.'
                  onChange={(e) => setForm({ ...form, phone: e.target.value })}
                  value={form.phone}
                />
              </div>
            </form>
          </div>
          <div className='div2'>
            <form className='contact-form'>
              <div className='form-group form-input'>
                <input
                  type='text'
                  className='form-control'
                  id='name'
                  placeholder='Subject *'
                  onChange={(e) =>
                    setForm({ ...form, subject: e.target.value })
                  }
                  value={form.subject}
                />
              </div>
              <div className='form-group py-1 form-input'>
                <textarea
                  rows='5'
                  placeholder='Message'
                  onChange={(e) =>
                    setForm({ ...form, message: e.target.value })
                  }
                  value={form.message}
                ></textarea>
              </div>
            </form>
            <button
              className={isValid() ? "disabled-btn" : "contact-btn"}
              onClick={handleSubmit}
              disabled={isValid()}
            >
              Submit
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
