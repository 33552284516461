import React from "react";
import { Row } from "react-bootstrap";
import TeamCard from "./TeamCard";
import { data } from "./data";

export default function TeamCards() {
  return (
    <>
      <Row className='px-5 mt-1 d-flex align-items-center justify-content-center mb-5'>
        {data?.map((item, index) => (
          <TeamCard member={item} key={index} />
        ))}
      </Row>
    </>
  );
}
