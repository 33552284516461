import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function Partner() {
  return (
    <Container className='d-flex align-items-center justify-content-center flex-column  m-auto py-5'>
      <span className='fs-1'>
        Technology
        <span style={{ color: "#e60405" }}> Partner </span> With ITKNOCKS
      </span>
      <Row>
        <Col
          lg={6}
          className='d-flex align-items-center justify-content-start flex-column   py-5'
        >
          <a href='https://itknocks.com/'>
            <img
              src='/Images/Itknocks-logo.png'
              alt='partner'
              className='partner-img'
            />
          </a>
        </Col>
        <Col className='d-flex align-items-center  flex-column  m-auto py-5'>
          <span>
            We are proud to be the managing partner in North America with{" "}
            <a href='https://itknocks.com/'>ITKnocks</a>. ITKnocks is an
            Australian IT consulting company. They deliver innovative solutions
            that leverage the Microsoft technology stack to help clients make
            data-driven decisions, unlock business growth, and boost staff
            efficiency.
          </span>
          <br />
          <span>
            They come from consulting backgrounds, and their team spans
            Australia, New Zealand, Canada, and Pakistan. So they know how to
            collaborate effectively with global organisations to develop
            innovative solutions to complex business problems.
          </span>
        </Col>
      </Row>
    </Container>
  );
}
