import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Services from "../../../Components/Services/Services";

const StructuralBIMContent = {
  desc: "We create Structural BIM models that accurately represent the dimensions and placements of steel components in the building design. We have thorough quality checks in place that ensure that the final deliverables are in compliance with the required codes and of high quality. Additionally, the shop drawings extracted from our error free 3D model lead to a smooth fabrication, assembly and installation process.",
  services: [
    "Structural 3D Modeling",
    "MEP Shop Drawing Services",
    "Quantity Takeoff Services",
  ],
  images: [
    "/Images/Services/Structural/1.jpg",
    "/Images/Services/Structural/2.jpg",
    "/Images/Services/Structural/3.jpg",
  ],
};

export default function StructuralBIM() {
  return (
    <Container fluid className='m-0 p-0 services-container'>
      <Row className='m-0 p-0 mb-5'>
        <Col sm={12} md={12} lg={12} className='m-0 services-title-container'>
          <span className='title-services align-self-center '>
            <span style={{ color: "#e60405" }}> Structural</span> BIM Services
          </span>
        </Col>
        <Services content={StructuralBIMContent} />
      </Row>
    </Container>
  );
}
