import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Home from "./Pages/Home";
import Team from "./Pages/Team";
import Contact from "./Pages/Contact";
import About from "./Pages/About";
import Why from "./Pages/Why";
import Costing from "./Pages/Services/Costing";
import TimeManagement from "./Pages/Services/TimeManagement";
import AssetsFacilityManagement from "./Pages/Services/AssetsFacilityManagement";
import BIMCoordination from "./Pages/Services/Model/BIMCoordination";
import ArchitecturalBIM from "./Pages/Services/Model/ArchitecturalBIM";
import BIMParametric from "./Pages/Services/Model/BIMParametric";
import MEPBIM from "./Pages/Services/Model/MEPBIM";
import ScanToBIM from "./Pages/Services/Model/ScanToBIM";
import StructuralBIM from "./Pages/Services/Model/StructuralBIM";
import Civil from "./Pages/Services/Engineering/Civil";
import Mechanical from "./Pages/Services/Engineering/Mechanical";
import Navbar from "./Components/Navigation/CustomNavbar";
import Banner from "./Components/Banner/Banner";
import Footer from "./Components/Navigation/Footer";
import Primavera from "./Pages/Training/Primavera";
import Synchro from "./Pages/Training/Synchro";
import "./App.css";

function App() {
  return (
    <Router>
      <Banner />
      <Navbar />
      <Routes>
        <Route>
          <Route exact path="/" element={<Home />} />
          <Route path="/team" element={<Team />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/training/primavera" element={<Primavera />} />
          <Route path="/training/synchro" element={<Synchro />} />
          <Route path="/about" element={<About />} />
          {/* <Route path='/why' element={<Why />} /> */}
          <Route path="/services/costing" element={<Costing />} />
          <Route
            path="/services/time-management"
            element={<TimeManagement />}
          />
          <Route
            path="/services/assets-facility-management"
            element={<AssetsFacilityManagement />}
          />
          <Route
            path="/services/model/bim-coordination"
            element={<BIMCoordination />}
          />
          <Route
            path="/services/model/architectural-bim"
            element={<ArchitecturalBIM />}
          />
          <Route
            path="/services/model/bim-parametric"
            element={<BIMParametric />}
          />
          <Route path="/services/model/mep-bim" element={<MEPBIM />} />
          <Route path="/services/model/scan-to-bim" element={<ScanToBIM />} />
          <Route
            path="/services/model/structural-bim"
            element={<StructuralBIM />}
          />
          <Route path="/services/engineering/civil" element={<Civil />} />
          {/* <Route
            path='/services/engineering/mechanical'
            element={<Mechanical />}
          /> */}

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          {/* <Route path='*' element={<NoMatch />} /> */}
        </Route>
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
